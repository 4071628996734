import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useI18next } from "gatsby-plugin-react-i18next";

import Header from "./Header";
import { regionRequest } from "../actions";
import "../../src/assets/stylesheets/application.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";

/**
 * shows the main layout
 * @param {import("react").ReactElement} children - shows main content
 * @param {string} [pageName=""] - identifies page used for custom css styling
 * @param {bool} [showHeader=true] - toggles the header visibility
 * @param {Element} [secondaryNav] - shows secondary navigation links in the header
 * @returns {any}
 */
const Layout = ({
  children,
  pageName = "",
  showHeader = true,
  secondaryNav,
  isMap = false,
}) => {
  const { unique_region_text } = useSelector(
    (state) => !state.region_loading && state.region_data
  );
  const { language } = useI18next();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(regionRequest(language));
  }, [dispatch, language]);

  let className = "";

  if (pageName.length) {
    className = className + " page-" + pageName;
  }

  return (
    <>
      <Helmet bodyAttributes={{ class: className }}>
        <title>{unique_region_text?.siteTitle ?? ""}</title>
      </Helmet>
      {showHeader && (
        <Header
          siteTitle={unique_region_text?.siteTitle ?? ""}
          siteDescription={unique_region_text?.siteDescription ?? ""}
          fadeIn={isMap}
        >
          {secondaryNav}
        </Header>
      )}
      <main>{children}</main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string,
  showHeader: PropTypes.bool,
  secondaryNav: PropTypes.element,
  isMap: PropTypes.bool,
};

export default Layout;
