import React from "react";
import PropTypes from "prop-types";
import { Nav } from "react-bootstrap";
import { Link } from "gatsby";
import logo from "../assets/images/logo.svg";

/**
 * shows logo as nav
 * @param {Element} [appLogo] - logo or icon
 * @param {string} [logoLinkPath="/"] - relative link path for the logo link
 * @param {string} [logoAltText="Application logo"] - alt text for the appLogo
 * @param {bool} [rtl=false] - toggle rtl style
 * @param {Element} children - optional extra children to display as sibling to the logo link
 * @returns {any}
 */
const NavLogo = ({
  appLogo = logo,
  logoLinkPath = "/",
  logoAltText = "Application logo",
  rtl = false,
  children,
}) => (
  <Nav className={rtl ? "float-right nav-rtl" : ""}>
    <Link to={logoLinkPath} className="nav-link header-link logo-link p-2">
      <img src={appLogo} alt={logoAltText} />
    </Link>
    {!!children && children}
  </Nav>
);

NavLogo.propTypes = {
  appLogo: PropTypes.any,
  logoLinkPath: PropTypes.string,
  logoAltText: PropTypes.string,
  rtl: PropTypes.bool,
  children: PropTypes.element,
};

export default NavLogo;
